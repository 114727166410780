input.parsley-success,
select.parsley-success,
textarea.parsley-success {
/*  color: #468847;
    background-color: #F6FFF4; 
    border: 1px solid #D6E9C6; */
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #FFF7FD;
/*  border: 1px solid #EED3D7; */
}

.parsley-errors-list {
  margin: 3px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.95em;
  font-style: bold;
  line-height: 1.2em;
  opacity: 0;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list * {
	color:#A94442;
}
.parsley-errors-list.filled {
  opacity: 1;
}
