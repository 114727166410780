@charset "UTF-8";
@font-face {
  font-family: 'noto-sans-thai';
  src: url("/fonts/notosansthai-bold-webfont.woff2") format("woff2"), url("/fonts/notosansthai-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* ตัวปกติ */
@font-face {
  font-family: 'noto-sans-thai';
  src: url("/fonts/notosansthai-regular-webfont.woff2") format("woff2"), url("/fonts/notosansthai-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'noto-sans-thai', Roboto, Lato, sans-serif;
  font-size: 100%;
  /*line-height: 1.8;*/
  color: #414042;
}

.headline {
  position: relative;
}

.headline:after {
  content: ' ';
  position: absolute;
  top: 110%;
  left: 50%;
  width: 140px;
  height: 9px;
  background: url("/img/web/wood_bar.jpg") no-repeat center;
  transform: translateX(-50%);
}

h2 {
  font-size: 5em;
  text-transform: uppercase;
  color: #414042;
  font-weight: 400;
  margin-bottom: 30px;
  position: relative;
}

h3 {
  font-size: 2.8em;
  line-height: 1.375em;
  color: #1E2D4D;
  font-weight: 700;
}

h4 {
  font-size: 1.9em;
  line-height: 1.5em;
  color: #303030;
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

#content .panel-body ul li h4 {
  font-size: 1.2em;
  color: #8A6D3B;
}

h5.caption {
  padding-top: 0em;
  padding-bottom: 1.2em;
}

#content div.panel-heading {
  padding: 0em;
  font-size: 1.0em;
}

#content div.panel-heading h3.panel-title a:hover {
  background-color: #1E2D4D;
  color: white;
}

#content h3.panel-title {
  font-size: 2.2em;
  font-weight: 400;
  line-height: 1.4em;
}

#content h3.panel-title a {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

#content .panel-body h3 {
  font-size: 2.5em;
}

h3.level2 {
  color: #8A6D3B;
}

#content .panel-body h4 {
  font-size: 2.2em;
  font-weight: 700;
}

.rightText {
  text-align: right;
}

.leftText {
  text-align: left !important;
}

p, table tr, ul.list-group li.list-group-item, .panel-body ul li {
  font-size: 1.8em;
}

p.image-caption {
  font-size: 1.65em;
}

.panel-body ul li ul li {
  font-size: 1.0em;
}

.references .panel-body ul {
  padding-left: 1.5em;
}

.references .panel-body ul li {
  font-size: 1.2em;
}

.references .panel-body ul li ul li {
  font-size: 1.0em;
}

.panel-body table {
  font-size: calc(0.5em + 0.4vw);
}

.references .panel-body table {
  font-size: calc(0.5em + 0.2vw);
}

.panel-body table ul {
  padding-left: 1em;
}

.panel-body table ul li {
  font-size: 1.0em;
}

#services p {
  text-align: justify;
}

.woodFont {
  color: #ECC889 !important;
}

.greyFont {
  color: #414042 !important;
}

.redFont {
  color: #A94442 !important;
}

.yellowFont {
  color: #8A6D3B !important;
}

.greenFont {
  color: #3C763D !important;
}

.brownFont {
  color: #8A6D3B !important;
}

.infoFont {
  color: #5bc0de !important;
}

.darkInfoFont {
  color: #4899AF !important;
}

.brownBg {
  background-color: #BC905E !important;
}

.woodBg {
  background-color: #ECC889 !important;
}

.breakAll {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
}

.noWrap {
  white-space: nowrap;
}

.woodHr {
  border-color: #ECC889 !important;
}

.paleHr {
  border-color: #E8E3D7 !important;
}

.woodBullet-15 {
  list-style-image: url("/img/web/wood_bullet_15.png");
}

.woodBullet-12 {
  list-style-image: url("/img/web/wood_bullet_12.png");
}

.whiteBullet-15 {
  list-style-image: url("/img/web/white_bullet_15.png");
}

.paleBullet-15 {
  list-style-image: url("/img/web/pale_bullet_15.png");
}

.paleBullet-12 {
  list-style-image: url("/img/web/pale_bullet_12.png");
}

.paleBullet-09 {
  list-style-image: url("/img/web/pale_bullet_09.png");
}

#news table th {
  font-size: 1.3em;
  line-height: 1.375em;
  color: #303030;
  font-weight: 400;
  margin-bottom: 30px;
}

table.text-center th, table.text-center td {
  text-align: center;
}

table.text-middle th, table.text-middle td {
  vertical-align: middle !important;
}

#content table th {
  font-size: 0.95em;
  font-weight: 400;
}

#content table td {
  font-size: 0.9em;
}

#content .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #FCF8E3;
}

#content table.table, #content table.table td, #content table.table th {
  border: 1px solid lightgray;
}

#content table.table {
  background-color: white;
}

#content table.table > thead > tr > th {
  color: white;
  background-color: #87704E;
}

#content table.left-head tr th {
  color: white;
  background-color: #87704E;
}

nav .container-fluid {
  margin-top: 0px;
  padding: 0px;
}

.container-fluid {
  padding: 30px 5% 40px 5%;
}

.bg-grey {
  background-color: #f6f6f6;
  background-clip: padding-box;
}

.navbar {
  padding: 0 !important;
  font-size: 16px !important;
  font-family: Roboto, Montserrat, sans-serif;
  text-transform: uppercase;
  background: url("/img/web/wood_menu.jpg") no-repeat bottom;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar li a, .navbar {
  color: #1E2D4D !important;
}

.knowledge-menu .glyphicon {
  color: #414042;
}

ul.navbar-right {
  margin-right: 2px;
}

ul.navbar-right a {
  padding-right: 18px;
  padding-left: 18px;
}

a.navbar-brand {
  position: absolute;
  left: 0em;
  padding-top: 0;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 20%;
  max-width: 184px;
  max-height: 70px;
  min-width: 150px;
  min-height: 40px;
  z-index: 1500;
}

a.navbar-brand img {
  padding: 0em 0.1em 0.1em 0.1em;
}

a.navbar-brand:hover {
  background: transparent !important;
}

.navbar-fixed-top {
  border: #F7941E;
  margin: 0px;
}

#topLogo:hover {
  background-color: #1E2D4D;
}

#topLogo img {
  display: inline-block;
  width: 100%;
}

.navbar-fixed-top {
  min-height: 0px;
}

.navbar-nav > li > a {
  padding-top: 0.53em;
  padding-bottom: 0.53em;
}

.navbar .navbar-header button {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.navbar-header #homeButton {
  position: absolute;
  font-size: 1.5em;
  padding-top: 0.1em;
  top: 0em !important;
  left: 0.5em;
  display: block;
}

nav.navbar div.navbar-header #homeButton a span {
  color: #ECC889;
  text-shadow: 1px 1px 2px #220604;
}

nav.navbar div.navbar-header #homeButton:hover a span {
  background: transparent !important;
  color: white;
}

.navbar .navbar-header div {
  padding-top: 0.28em;
  padding-bottom: 0.28em;
}

.navbar .navbar-header div span {
  color: #1E2D4D;
}

.collapseNavMenu:hover, .collapseNavMenu:focus {
  background: transparent !important;
}

.navbar button.collapseNavMenu:hover span, #homeButton:hover span, #homeButton:focus span {
  background-color: #1E2D4D;
}

.navbar-nav li.active a {
  color: #F0D1A8 !important;
  background-color: #220604 !important;
}

.navbar li a:hover, .collapseNavButton:hover, .collapseNavButton:hover span {
  background-color: #1E2D4D !important;
  color: white !important;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
  color: #fff !important;
}

#myNavbar {
  padding-top: 0%;
  border: none;
}

a.clickable-panel {
  width: 100%;
  height: 100%;
  display: block;
}

a.clickable-panel:hover, a.clickable-panel:focus {
  text-decoration: none;
}

a.panel-bottom {
  display: block;
  height: 32px;
  padding: 0;
  margin-top: -1.5em;
}

a.panel-bottom img {
  height: 10px;
  margin-top: 15px;
}

.jumbotron {
  background: url("/img/web/main.jpg") no-repeat center fixed;
  background-size: cover;
  padding: 175px 0px;
  height: 100vh;
  font-family: Lato, Montserrat, sans-serif;
  text-shadow: 2px 2px 4px #808080;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.jumbotron h1, .jumbotron .glyphicon {
  color: #fff;
  font-weight: 700;
}

div.progress-nutrition {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
}

.progress .progress-bar-label {
  margin-left: 0.2em;
}

text-right {
  text-align: right;
}

div.float-left-clear-none {
  float: left;
  clear: none;
}

#about_us_img {
  margin-top: 1.5em;
  margin-left: 20%;
  width: 75%;
  height: 50em;
  background: url("/img/web/about.jpg") no-repeat center;
  background-size: cover;
}

.knowledge-menu, .knowledge-section {
  background: url("/img/web/knowledge_menu.jpg") no-repeat center center fixed;
  background-size: cover;
  padding: 30px 0px;
  height: 450px;
}

.knowledge-menu h2, .knowledge-section h2 {
  text-shadow: 1px 1px 2px #FFF, -1px -1px 2px #FFF, 1px 1px 3px #FFF, -1px -1px 3px #FFF,2px 2px 2px #FFF, -2px -2px 2px #FFF, 1px -1px 2px #FFF, -1px 1px 2px #FFF, 1px -1px 3px #FFF, -1px 1px 3px #FFF,2px -2px 2px #FFF, -2px 2px 2px #FFF;
}

.knowledge-menu .list-group, .knowledge-section .list-group {
  margin-bottom: 0px;
}

.knowledge-menu .list-group .list-group-item, .knowledge-section .list-group .list-group-item {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #ECC889;
}

.knowledge-menu ul.list-group li.list-group-item a, .knowledge-section ul.list-group li.list-group-item a {
  color: #313032;
}

.knowledge-menu ul.list-group li.knowledge-active {
  background-color: #220604 !important;
}

.knowledge-menu ul.list-group li.knowledge-active a {
  color: #F0D1A8 !important;
}

.knowledge-menu ul.list-group li, #knowledges ul.list-group li {
  padding: 0;
}

.knowledge-menu ul.list-group li a:hover, #knowledges ul.list-group li a:hover {
  background-color: #1E2D4D;
  color: white;
}

.knowledge-menu .row, #knowledges .row {
  padding: 0em 2em;
  font-size: 1.2em;
  line-height: 4em;
  letter-spacing: -0.02em;
}

#knowledge_img {
  width: 100%;
  background: url("/img/web/knowledge.jpg") no-repeat top;
  background-size: cover;
}

div.pest-control table img {
  max-width: 200px;
  height: auto;
  width: 20vw;
}

div.pest-control div.image_ref {
  margin-top: -1.55em;
  margin-left: 0.4em;
  text-align: left;
}

div.pest-control div.image_ref a {
  color: white;
}

.image_center {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.example {
  background-color: #FDFFFC;
  box-shadow: -1px -1px 2px 0 rgba(223, 240, 216, 0.6) inset;
  border: solid 1px #DFF0D8;
  border-radius: 7px;
  padding: 1.0em 1.5em;
}

.logo-small {
  color: #F7941E;
  font-size: 50px;
}

.logo {
  color: #F7941E;
  font-size: 200px;
}

.thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
  background-color: #ffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

#team .thumbnail img {
  width: 70%;
  height: 70%;
  padding-top: 20px;
}

.thumbnail p {
  width: 100%;
  height: 100%;
  padding: 10px 15px 0px 15px;
}

.tab-content {
  background-color: white;
  border-left: 1px solid lightgrey;
}

.carousel-control.right, .carousel-control.left {
  background-image: none;
  color: #f4511e;
}

.carousel-indicators li {
  border-color: #f4511e;
}

.carousel-indicators li.active {
  background-color: #f4511e;
}

.item h4 {
  font-size: 19px;
  line-height: 1.375em;
  font-weight: 400;
  font-style: italic;
  margin: 70px 0;
}

.item span {
  font-style: normal;
}

/*
  .panel {
      border: 1px solid #f4511e;
      border-radius:0 !important;
      transition: box-shadow 0.5s;
  }
  .panel:hover {
      box-shadow: 5px 0px 40px rgba(0,0,0, .2);
  }
  .panel-footer .btn:hover {
      border: 1px solid #f4511e;
      background-color: #fff !important;
      color: #f4511e;
  }
  .panel-heading {
      color: #fff !important;
      background-color: #f4511e !important;
      padding: 25px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
  }
  .panel-footer {
      background-color: white !important;
  }
  .panel-footer h3 {
      font-size: 32px;
  }
  .panel-footer h4 {
      color: #aaa;
      font-size: 14px;
  }
  .panel-footer .btn {
      margin: 15px 0;
      background-color: #f4511e;
      color: #fff;
  }
  */
footer .glyphicon {
  font-size: 20px;
  margin-bottom: 20px;
  color: #f4511e;
}

.slideanim {
  visibility: hidden;
}

.slide {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 991px) {
  .col-sm-3-thumbnail {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
  .medHide {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .smallShow {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .col-sm-4 {
    text-align: center;
    margin: 25px 0;
  }
  .btn-lg {
    width: 100%;
    margin-bottom: 35px;
  }
  .jumbotron {
    padding: 115px 0px;
    font-family: Lato, Montserrat, sans-serif;
  }
  .jumbotron h1 {
    letter-spacing: -2px;
    font-size: 5em;
  }
  .navbar li {
    padding-left: 2em;
  }
  ul.navbar-right {
    margin-right: 20px;
  }
  .smallHide {
    display: none !important;
  }
  #knowledges {
    height: 650px;
  }
  .knowledge-menu {
    height: 700px;
  }
  #content .panel table {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  #content .panel table td, #content .panel table th {
    padding: 0.25em;
  }
  div.pest-control .herb-panel div.panel-body {
    padding-left: 0.5em;
    padding-right: 0em;
  }
  div.pest-control table ul {
    list-style-position: inside;
    padding-left: 0.1em;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar li {
    padding-left: 0em;
  }
  ul.navbar-right {
    margin-right: 2px;
  }
  .smallHide {
    display: block !important;
  }
  #knowledges, .knowledge-menu {
    height: 450px;
  }
  .jumbotron {
    height: 550px;
    background-attachment: scroll;
    background-size: auto 550px;
    padding: 115px 0px;
  }
  .knowledge-menu, .knowledge-section {
    background-attachment: scroll;
    background-size: auto 450px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .jumbotron, .knowledge-menu, .knowledge-section {
    background-attachment: scroll;
    background-size: auto 100vh;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    font-size: 150px;
  }
  .jumbotron h1 {
    letter-spacing: -2px;
    font-size: 4.5em;
  }
  h2 {
    letter-spacing: -2px;
    font-size: 4.5em;
  }
}

img.member-portrait {
  max-width: 300px;
}

img.services-image {
  max-width: 450px;
}

/*# sourceMappingURL=app.css.map */
